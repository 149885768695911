import React from "react";
import { type ReactNode, useContext, useMemo } from "react";
import type { AppPages } from "../../routing/AppPages";
import { AppRoutes, getAbsolutePath } from "../../routing/AppRoutes";
import type { EnsuredPath, PathCollection } from "../../routing/ChildRoutes";
import { useContractToken } from "../ContractParams";
import type { ContractToken } from "../model/ContractViewModel";

export interface IContractUrlsContext {
  urls: ContractPaths;
}

export const ContractUrlsContext =
  React.createContext<IContractUrlsContext | null>(null);

export type ContractPaths = PathCollection<AppPages>;

export function getContractPath(
  key: AppPages,
  token: ContractToken,
): EnsuredPath {
  const absolute = getAbsolutePath(key);
  return getContractPathFromPathname(absolute, token);
}

export function getContractPathFromPathname(
  pathname: string,
  token: ContractToken,
): EnsuredPath {
  const search = new URLSearchParams({ token }).toString();

  return { pathname, search: `?${search}` };
}

export function getContractPaths(token: ContractToken) {
  const paths = Object.keys(AppRoutes) as AppPages[];
  const result = paths.reduce(
    (acc, key) => {
      acc[key as AppPages] = getContractPath(key, token);
      return acc;
    },
    {} as { [key in AppPages]: EnsuredPath },
  );
  return result;
}

export function ContractUrlsProvider({ children }: { children: ReactNode }) {
  const token = useContractToken();

  const urls = useMemo(() => getContractPaths(token), [token]);

  return (
    <ContractUrlsContext.Provider
      value={{
        urls,
      }}
    >
      {children}
    </ContractUrlsContext.Provider>
  );
}

export function useContractPaths(): ContractPaths {
  const context = useContext(ContractUrlsContext);

  if (!context) throw new Error("No ContractUrlsContext found!");

  const { urls } = context;

  return urls;
}
