import type { HTMLAttributes } from "react";
import { Link as RouterLink, type To } from "react-router-dom";
import styled from "styled-components";
import type { TrackEventProps } from "@naf/teamscheme";
import { useTracking } from "../../Tracking";

export interface RouterLinkAction {
  linkTo: To;
  onClick?(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
}

export interface AnchorLinkAction {
  href: string;
  onClick?(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
}

export interface ButtonAction {
  onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

export type LinkAction = RouterLinkAction | AnchorLinkAction | ButtonAction;

export interface LinkProps
  extends Omit<HTMLAttributes<HTMLElement>, "onClick"> {
  action: LinkAction;
  trackEvent?: TrackEventProps;
}

const Link = styled(function ({
  action,
  children,
  trackEvent,
  ...props
}: LinkProps) {
  const tracking = useTracking();

  if ("linkTo" in action) {
    return (
      <RouterLink
        to={action.linkTo}
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          if (trackEvent) tracking.event(trackEvent);
          action.onClick?.(e);
        }}
        {...props}
      >
        {children}
      </RouterLink>
    );
  }

  if ("href" in action) {
    return (
      <a
        href={action.href}
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          if (trackEvent) tracking.event(trackEvent);
          action.onClick?.(e);
        }}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <button
      type="button"
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (trackEvent) tracking.event(trackEvent);
        action.onClick?.(e);
      }}
      {...props}
    >
      {children}
    </button>
  );
})``;

export default Link;
