import React, { useState, type ReactNode, useContext, useEffect } from "react";
import type { ApiError } from "../../api/ApiClient";

export enum SavingState {
  None = 0,
  Loading = 1,
  Saved = 2,
  Error = 3,
}

export function useSavingState(timeout?: number) {
  const { isSaving, saveError } = useContractHeaderContext();
  const [wasSaving, setWasSaving] = useState(false);
  const [successfulSave, setSuccessfulSave] = useState(false);
  const timeoutDelay = timeout ?? 5000;

  useEffect(() => {
    if (isSaving) {
      setWasSaving(true);
    }
  }, [isSaving]);

  useEffect(() => {
    if (!isSaving && wasSaving && !saveError) {
      setSuccessfulSave(true);
    }
  }, [isSaving, saveError, wasSaving]);

  useEffect(() => {
    if (successfulSave) {
      const timeout = setTimeout(() => {
        setSuccessfulSave(false);
        setWasSaving(false);
      }, timeoutDelay);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [successfulSave, timeoutDelay]);

  if (saveError) return SavingState.Error;
  if (isSaving) return SavingState.Loading;
  if (successfulSave) return SavingState.Saved;
  return SavingState.None;
}

interface IContractHeadeerContext {
  isSaving: boolean;
  setIsSaving(isSaving: boolean): void;
  hasSaved: boolean;
  setHasSaved(hasSaved: boolean): void;
  saveError: ApiError | null;
  setSaveError(saveError: ApiError | null): void;
  showStatic: boolean;
  setShowStatic(showStatic: boolean): void;
}

const ContractHeaderContext =
  React.createContext<IContractHeadeerContext | null>(null);

export function useContractHeaderContext() {
  const context = useContext(ContractHeaderContext);

  if (!context) throw new Error("No ContractHeaderContext found!");

  return context;
}

export function ContractHeaderContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [showStatic, setShowStatic] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [saveError, setSaveError] = useState<ApiError | null>(null);

  return (
    <ContractHeaderContext.Provider
      value={{
        isSaving,
        setIsSaving,
        hasSaved,
        setHasSaved,
        saveError,
        setSaveError,
        showStatic,
        setShowStatic,
      }}
    >
      {children}
    </ContractHeaderContext.Provider>
  );
}

export function useStaticContractHeader() {
  const context = useContractHeaderContext();
  useEffect(() => {
    context.setShowStatic(true);
    return () => context.setShowStatic(false);
  }, [context]);
}
