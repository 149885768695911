import * as Sentry from "@sentry/browser";
import {
  Component,
  type HTMLAttributes,
  type ErrorInfo,
  type ReactNode,
} from "react";
import styled from "styled-components";
import { nafColor } from "@nafcore/theme";
import { NafLogo } from "./shared/logos/NafLogo";
import { copyFooterData } from "./shared/elements/CopyFooter";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class StandaloneErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.log({ error, info });
    Sentry.withScope((scope) => {
      scope.setExtras({ info: info });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <StandaloneErrorPage>
          <h1>Feil &ndash; NAF Kjøpekontrakt</h1>
          <p>
            Det ser ut som noe gikk galt! Vennligst lukk nettleseren og åpne den
            på nytt.
          </p>
        </StandaloneErrorPage>
      );
    }

    return this.props.children;
  }
}

const StandaloneErrorPage = styled(function ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  const { year, appVersion } = copyFooterData;
  return (
    <div {...props}>
      <header>
        <div>
          <NafLogo />
        </div>
        <div>
          <span>Veihjelp</span>
          <strong>08&nbsp;505</strong>
        </div>
      </header>
      <main>
        <div>{children}</div>
      </main>
      <footer>
        <p>NAF Kjøpekontrakt</p>
        <p>
          &copy; {year} NAF v{appVersion}
        </p>
      </footer>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: sans-serif;

  main {
    flex: 1;
    padding: 1em;

    & > div {
      max-width: 720px;
      margin: auto;
    }
  }

  header {
    display: flex;
    padding: 1em;
    gap: 1em;
    align-items: center;

    img {
      display: block;
    }

    div:last-child {
      display: flex;
      flex-direction: column;
    }
  }

  footer {
    color: white;
    background: ${nafColor.primary.spruce};
    padding: 1em;

    p {
      margin: 0.5em 0;
    }
  }
  `;
