import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { captureException } from "@sentry/browser";
import type { LoaderFunction } from "react-router-dom";
import { api, type ApiError } from "../api/ApiClient";
import type { ContractViewModel } from "./model/ContractViewModel";
import type { UnauthorizedContractErrorResult } from "./UnauthorizedContractErrorResult";

interface ConflictResult {
  archived: boolean;
  quarantined: boolean;
}
export interface LoadedContract {
  contract: ContractViewModel;
  role: Role;
}
export type ContractLoaderResult =
  | { conflict: ConflictResult }
  | { loaded: LoadedContract }
  | { unauthorized: UnauthorizedContractErrorResult };

export const contractLoader: LoaderFunction = async ({
  request,
}): Promise<null | ContractLoaderResult> => {
  if (typeof window === "undefined") return null;
  const token = new URL(request.url).searchParams.get("token");
  if (!token) return null;

  try {
    const contract = await api.get<ContractViewModel>(
      `contract/token/${token}`,
    );
    const role =
      contract && "buyerToken" in contract ? Role.Buyer : Role.Seller;
    return { loaded: { contract, role } };
  } catch (error: unknown) {
    console.log({ error });
    const apiError = error as ApiError;
    if (apiError.status === 409) {
      const conflictResult = apiError.result as ConflictResult;
      return { conflict: conflictResult };
    }
    if (apiError.status === 401) {
      const unauthorizedResult =
        apiError.result as UnauthorizedContractErrorResult;
      return { unauthorized: unauthorizedResult };
    }
    captureException(apiError);
    throw new Error("Failed to fetch contract");
  }
};
contractLoader.hydrate = false;
