import type { MenuListItem } from "@naf/teamscheme";
import { useMemo } from "react";
import { EVENTS } from "../../Tracking";
import { AppPages } from "../../routing/AppPages";
import { getAbsolutePath } from "../../routing/AppRoutes";
import { Lightbulb } from "@styled-icons/material/Lightbulb";
import { Lightbulb as LightbulbOutline } from "@styled-icons/material-outlined/Lightbulb";
import { Help as HelpIcon } from "@styled-icons/material/Help";
import { HelpOutline as HelpIconOutline } from "@styled-icons/material/HelpOutline";

export interface OrderedMenuListItem extends MenuListItem {
  order: number;
}

export type MaybeOrderedMenuListItem = MenuListItem | OrderedMenuListItem;

export function createFaqLinkItem(): MaybeOrderedMenuListItem {
  const text = "Vanlige spørsmål";
  const props: MenuListItem = {
    key: "FAQ",
    text,
    icon: {
      icon: <HelpIconOutline size={24} />,
      hoverIcon: <HelpIcon size={24} />,
    },
    linkTo: getAbsolutePath(AppPages.AboutFaq),
    trackEvent: { ...EVENTS.HEADER.FAQ, label: text },
  };
  return props;
}

export function useFaqLinkItem() {
  return useMemo(() => createFaqLinkItem(), []);
}

export function createAboutLinkItem(): MaybeOrderedMenuListItem {
  const text = "Slik fungerer det";
  const props: MenuListItem = {
    key: "About",
    text,
    linkTo: getAbsolutePath(AppPages.AboutHowItWorks),
    icon: {
      icon: <LightbulbOutline size={24} />,
      hoverIcon: <Lightbulb size={24} />,
    },
    trackEvent: { ...EVENTS.HEADER.FAQ, label: text },
  };
  return props;
}

export function useAboutLinkItem() {
  return useMemo(() => createAboutLinkItem(), []);
}

export function combineMenuItems(
  items: (MaybeOrderedMenuListItem | null)[],
): OrderedMenuListItem[] {
  const filtered = items.filter((e): e is MaybeOrderedMenuListItem => !!e);

  return filtered
    .map((x, index) => {
      if ("order" in x) {
        const { order, ...rest } = x;
        return { order: index + order * filtered.length, ...rest };
      }

      return { order: index, ...x };
    })
    .sort((a, b) => a.order - b.order);
}
