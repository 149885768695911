import { ButtonLink, type ButtonLinkVariant } from "@naf/teamscheme";
import { TextLink } from "@naf/teamscheme";
import { EVENTS } from "../../Tracking";
import PhoneNumber from "../../formatting/PhoneNumber";

const customerServicePhone = "08505";
const customerServicePhoneFromAbroad = "+4723213100";
const customerServiceEmail = "kundeservice@naf.no";

export function CustomerServicePhoneTextLink() {
  return (
    <TextLink href={`tel:+47${customerServicePhone}`}>
      <PhoneNumber number={customerServicePhone} />
    </TextLink>
  );
}

export function CustomerServicePhoneButtonLink({
  variant = "negative",
}: {
  variant?: ButtonLinkVariant;
}) {
  return (
    <ButtonLink variant={variant} href={`tel:+47${customerServicePhone}`}>
      <PhoneNumber number={customerServicePhone} />
    </ButtonLink>
  );
}

export function CustomerServicePhoneFromAbroadButtonLink({
  variant = "negative",
}: {
  variant?: ButtonLinkVariant;
}) {
  return (
    <ButtonLink
      variant={variant}
      href={`tel:${customerServicePhoneFromAbroad}`}
    >
      <PhoneNumber number={customerServicePhoneFromAbroad} />
    </ButtonLink>
  );
}

export function CustomerServiceEmailTextLink() {
  return (
    <TextLink href={`mailto:${customerServiceEmail}`}>
      {customerServiceEmail}
    </TextLink>
  );
}

export function CustomerServiceEmailButtonLink({
  variant = "negative",
}: {
  variant?: ButtonLinkVariant;
}) {
  return (
    <ButtonLink
      variant={variant}
      href={`mailto:${customerServiceEmail}`}
      trackEvent={EVENTS.FOOTER.EMAIL_CUSTOMER_SERVICE}
    >
      {customerServiceEmail}
    </ButtonLink>
  );
}
